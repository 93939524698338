import React, { FC, useEffect, useRef, useState } from 'react';
import GlobalPagewrapper from '../../../../../components/Global/GlobalPagewrapper';
import GlobalSelect from '../../../../../components/Global/GlobalSelect';
import { TMyScore } from '../../../../../types/countryPool.type';
import { useCountryPoolActions } from '../../../../../store/countryPool/poolAction';
import HoverBorderAnimation from '../../../../../components/HoverBorderAnimation';

type MyScoreProps = {
  data: TMyScore[] | null;
};

const MyScore: FC<MyScoreProps> = ({ data }) => {
  const { getMyScore } = useCountryPoolActions();

  const [filterScoreValue, setFilterScoreValue] = React.useState<any>({
    year: 2024,
  });
  const [prevFilters, setPrevFilters] = useState<any>(filterScoreValue);

  const [myScore, setMyScore] = useState(data);
  const poolArray = ['Pool Name', 'Score'];

  let superNodesRewardsType = [];
  for (let i = 2000; i <= new Date().getFullYear(); i++) {
    superNodesRewardsType.push({
      name: `${i}`,
      value: `${i}`,
    });
  }

  const TotalRewardsChartFill = [{ match: { id: 'Rewards' }, id: 'gradient1' }];

  const rows = myScore?.map((score) => {
    return {
      poolName: (
        <div className="flex items-center gap-1 ">
          <div className="w-2 h-2 rounded-full bg-[#AF52DE]"></div>
          <span>{score?.poolName}</span>
        </div>
      ),
      score: score.score,
    };
  });

  useEffect(() => {
    // Check if the filter value has changed
    if (JSON.stringify(prevFilters) !== JSON.stringify(filterScoreValue)) {
      getMyScore(filterScoreValue);
      setPrevFilters({ ...prevFilters }); // Update previous filters
    }
  }, [filterScoreValue]);

  useEffect(() => setMyScore(data), [data]);
  return (
    <HoverBorderAnimation
      className={`h-[344px] border radius-5px !rounded-lg  col-span-12 bg-white  relative !p-0 w-full xl:w-1/2`}
      hoverAnimation={true}
    >
      <div className="flex items-center justify-between w-full pb-4 mt-3 border-b-2 px-9 ">
        <p className="text-xl font-medium">My Score</p>
        <GlobalSelect
          name="type"
          placeholder="Type"
          isError={false}
          value={filterScoreValue.selectedStatus}
          getSelectedValue={(item: string) => {
            setFilterScoreValue({ year: item });

            // setShouldFetchData(true);
          }}
          options={superNodesRewardsType.sort((a: any, b: any) =>
            b.name.localeCompare(a.name)
          )}
          className={` !rounded-md !min-w-24 ${filterScoreValue.selectedStatus ? '!w-max ' : '!w-[122px]'} `}
          classNameSelect="!border-defaultBorder !rounded-md py-2.5 px-5 mr-6 "
          optionsWidth="w-max"
        />
      </div>
      <div className="relative">
        <div className="backdrop-blur-sm w-[100%] h-[100%] absolute top-1 flex justify-center items-center uppercase">
          <p>Coming Soon</p>
        </div>

        <div className="flex justify-between p-2 px-9 !text-secondaryGrey  overflow-auto small-scroll">
          {(poolArray ?? [])?.map((ele, index) => <p key={index}>{ele}</p>)}
        </div>

        {rows?.map((ele, index) => (
          <div
            className="flex justify-between p-4 px-9 !text-black border-b-[1px] text-sm font-normal  bg-[#FAFAFA] mb-1 border-[#E6E6E6] leading-none"
            key={index}
          >
            {ele.poolName} <p>{ele.score}</p>
          </div>
        ))}
      </div>
      {/* <div className="!h-[20rem]">
          <>
            <LineChart
              containerClass="!p-0 !pr-2"
              // data={TotalRewardsChartData}
              // data={RewardsCalculatorChartData}
              data={[
                {
                  id: 'WalletGraph',
                  color: '#AF52DE',
                  data: [
                    {
                      x: 'Jan',
                      y: 1,
                    },
                    {
                      x: 'Feb',
                      y: 2,
                    },
                    {
                      x: 'Mar',
                      y: 2,
                    },
                    {
                      x: 'Apr',
                      y: 6,
                    },
                    {
                      x: 'May',
                      y: 8,
                    },
                    {
                      x: 'Jun',
                      y: 9,
                    },
                    {
                      x: 'Jul',
                      y: 7,
                    },
                  ],
                },
                {
                  id: 'WalletGraph-2',
                  color: '#32ADE6',
                  data: [
                    {
                      x: 'Jan',
                      y: 9,
                    },
                    {
                      x: 'Feb',
                      y: 8,
                    },
                    {
                      x: 'Mar',
                      y: 9,
                    },
                    {
                      x: 'Apr',
                      y: 3,
                    },
                    {
                      x: 'May',
                      y: 5,
                    },
                    {
                      x: 'Jun',
                      y: 4,
                    },
                    {
                      x: 'Jul',
                      y: 3,
                    },
                  ],
                },
              ]}
              defs={TotalRewardsChartDefs}
              fill={TotalRewardsChartFill}
              margin={{ top: 50, right: 0, bottom: 50, left: 50 }}
              isInteractive={true}
              enablePoints={true}
              enableGridX={true}
              enableGridY={true}
              enableArea={false}
              // gridYValues={yAxisValues}
              colors={['#32ADE6', '#AF52DE']}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: 36,
                legendPosition: 'middle',
                truncateTickAt: 0,
              }}
              legends={[]}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickValues: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: 36,
                legendPosition: 'middle',
                truncateTickAt: 0,
                format: (value: string | number) => `${value}`,
              }}
              tootlipColor={'#292929'}
              showSub={true}
              theme={{
                text: {
                  fontSize: '12px',
                  fill: '#AAAAAA',
                },
                grid: {
                  line: {
                    stroke: '#E0E0E0',
                    strokeWidth: 1,
                    strokeDasharray: '0',
                  },
                  top: {
                    line: {
                      stroke: 'transparent',
                      strokeWidth: 0,
                    },
                  },
                },
                axis: {
                  ticks: {
                    line: {
                      stroke: '#FFFFFF40',
                    },
                    text: {
                      fontSize: '8px',
                      fill: '#7D7D7D',
                    },
                  },
                },
              }}
            />
          </>
        </div> */}
    </HoverBorderAnimation>
  );
};
export default MyScore;
