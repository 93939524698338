import React, { useEffect, useState, memo, FC, useRef } from 'react';
import GlobalTable from '../../../../../components/Global/GlobalTable';
import GlobalPageWrapper from '../../../../../components/Global/GlobalPagewrapper';
import useDebounce from '../../../../../hooks/useDebounceHook';
import moment from 'moment';
import { CrownIcon } from '../../../../../images/svgs';
import { formatDateToString } from '../../../../../utils/helper';
import { useCountryPoolActions } from '../../../../../store/countryPool/poolAction';
import {
  IRewardHistory,
  TokenType,
} from '../../../../../types/countryPool.type';
import GlobalSelect, {
  IGlobalSelect,
} from '../../../../../components/Global/GlobalSelect';
import PlatformComingSoon from '../../../../../components/ExternApps/PlatformComingSoon';

type PoolRewardHistoryProps = {
  data: IRewardHistory;
  typeList: TokenType[] | null;
};

const PoolRewardHistory: FC<PoolRewardHistoryProps> = memo(
  ({ data, typeList }) => {
    const isComingsoon = true;
    const [rewardHistory, setRewardHistory] = useState(data);
    const [typeListData, setTypeListData] = useState(typeList);

    const { getRewardHistory } = useCountryPoolActions();
    const [shouldFetchData, setShouldFetchData] = useState(true);

    const [filterREwardsValue, setFilterRewardValue] = React.useState<any>({
      page: 1,
      limit: 10,
      fromDate: null,
      toDate: null,
      type: '',
    });
    const [query, setQuery] = useState('');

    const [resetFilter, setResetFilter] = useState(false);
    const [isLoading, setLoading] = useState({
      searchLoading: false,
      dataFilter: false,
    });
    const {
      debouncedValue: debouncedSearchValue,
      setDebouncedValue: setSearchDebounce,
    } = useDebounce(
      query,
      1000
      // () => {
      //   filterREwardsValue?.query != null
      //     ? setLoading({ ...isLoading, searchLoading: true })
      //     : (() => {})();
      // }
    );
    const prevFilterActiveValue = useRef(filterREwardsValue);
    const prevDebouncedSearchValue = useRef(debouncedSearchValue);
    useEffect(() => {
      if (
        prevFilterActiveValue.current !== filterREwardsValue ||
        prevDebouncedSearchValue.current !== debouncedSearchValue
      ) {
        const fetchData = async () => {
          setResetFilter(false);
          setLoading({ ...isLoading, dataFilter: true });
          await getRewardHistory({
            ...filterREwardsValue,
            query: debouncedSearchValue,
          }).then((response) => {
            setRewardHistory(response?.data?.data);
            setLoading({
              searchLoading: false,
              dataFilter: false,
            });
          });
        };
        prevFilterActiveValue.current = filterREwardsValue;
        prevDebouncedSearchValue.current = debouncedSearchValue;
        fetchData();
      }
    }, [filterREwardsValue, debouncedSearchValue]);

    const rows = rewardHistory?.list?.map((ele) => {
      return {
        poolName: ele?.poolName,
        poolTokens: ele?.totalPrice,
        rewards: (
          <div className="flex items-center gap-1">
            <CrownIcon className="w-4"></CrownIcon>
            {ele?.rewardAmount || 0}
          </div>
        ),
        rewardsTowken: ele?.rewardToken,
        date: (
          <span className="!text-primaryGrey">
            {ele?.createdAt
              ? formatDateToString(new Date(ele?.createdAt), false)
              : '-'}
          </span>
        ),
      };
    });

    const handleReset = () => {
      setResetFilter(true);

      setFilterRewardValue({
        page: 1,
        limit: 10,
        fromDate: null,
        toDate: null,
        type: '',
      });
      setQuery('');
      setSearchDebounce('');
    };

    const headCells = [
      {
        id: 'poolName',
        label: 'Pool Name',
        dataClass: '!text-primaryGrey  !font-normal !pl-6',
      },
      {
        id: 'poolTokens',
        label: 'Pool Tokens',
        dataClass: '!text-primaryGrey  !font-normal  ',
      },
      {
        id: 'rewards',
        label: 'Rewards',
        dataClass: '!text-primaryGrey  !font-normal',
      },
      // {
      //   id: 'rewardsTowken',
      //   label: 'Rewards Token',
      //   dataClass: '!text-primaryGrey  !font-normal',
      // },
      {
        id: 'date',
        label: 'Date',
        dataClass: '!text-primaryGrey  !font-normal',
      },
    ];
    const handleDateDate = (startDate: any, endDate: any) => {
      const from = moment(startDate).format('YYYY-MM-DD');
      const to = moment(endDate).format('YYYY-MM-DD');
      setFilterRewardValue({
        ...filterREwardsValue,
        ...{ fromDate: from, toDate: to },
      });
    };

    const tokenType: IGlobalSelect[] =
      typeListData?.map((type, index) => {
        return {
          name: type?.symbol,
          value: type?.symbol,
        };
      }) || [];

    const tableDropdowns = (
      <div className="flex flex-row space-x-3 sm:items-center">
        {/* <GlobalSelect
        name="type"
        placeholder="Reward Token"
        isError={false}
        value={filterREwardsValue.type}
        getSelectedValue={(item: string) => {
          setFilterRewardValue({
            ...filterREwardsValue,
            type: item,
          });
          setShouldFetchData(true);
        }}
        options={tokenType}
        className={` !rounded-md !min-w-24 ${filterREwardsValue.type ? '!w-max ' : '!w-[135px]'} `}
        classNameSelect="!border-defaultBorder !rounded-md py-2.5 px-5 mr-6 "
        optionsWidth="w-max"
      /> */}
        <button
          className="h-10 text-xs font-medium text-primary"
          type="button"
          onClick={handleReset}
        >
          Reset
        </button>
      </div>
    );

    useEffect(() => {
      setRewardHistory(data);
    }, [data]);

    useEffect(() => {
      setTypeListData(typeList);
    }, [typeList]);

    return (
      <GlobalPageWrapper containerClass="!p-0 !border-none !bg-transparent relative !rounded-lg ">
        <p className="mb-3 text-lg font-semibold ">Your Pool Reward History</p>
        <div className={`${isComingsoon && 'blur-md'}`}>
          <GlobalTable
            stopHoverEffect={true}
            totalPages={1}
            isLoading={isLoading?.dataFilter}
            dateArrowAllow={true}
            fromDateClassName={`py-2.5 px-5 !rounded-md !min-w-24 !w-[122px]`}
            toDateClassName={`py-2.5 px-5 !rounded-md !min-w-24 !w-[122px]`}
            containerStyles="!border-none rounded-lg"
            paginationPosition="right"
            rows={rows?.length ? rows : []}
            headCells={headCells}
            showTotalRecords={false}
            searchIsLoading={isLoading?.searchLoading}
            searchFieldPlaceholderText="Search in your community"
            handlePageChange={(page) => {
              setFilterRewardValue({ ...filterREwardsValue, ...{ page } });
            }}
            setSearchValue={(e: any) => {
              setQuery(e);
            }}
            tableWidth="true"
            resetDateFilter={resetFilter}
            tableDropdowns={tableDropdowns}
            handleDateDate={handleDateDate}
            searchValue={query}
            isCellClass={false}
            bodyClass={'!min-h-0 !rounded-lg'}
            bodyStyles="!text-black !text-normal  !font-normal"
            // tableDropdowns={
            //             <div className="flex flex-row space-x-3 sm:items-center">
            //                 <GlobalSelect
            //                     name="type"
            //                     placeholder="Minter Type"
            //                     isError={false}
            //                     value={filters.selectedStatus}
            //                     getSelectedValue={(item: string) => {
            //                         setFilters({
            //                             ...filters,
            //                             selectedStatus: item,
            //                         });
            //                         setShouldFetchData(true);
            //                     }}
            //                     options={superNodesRewardsType}
            //                     className={` !rounded-md !min-w-24 ${filters.selectedStatus ? '!w-max ' : '!w-[122px]'} `}
            //                     classNameSelect="!border-defaultBorder !rounded-md py-2.5 px-5 mr-6 "
            //                     optionsWidth="w-max"
            //                 />
            //                 <button
            //                     className="h-10 text-xs font-medium text-primary"
            //                     type="button"
            //                     onClick={handleReset}
            //                 >
            //                     Reset
            //                 </button>
            //             </div>
            //         }
          ></GlobalTable>
        </div>

        {isComingsoon && <PlatformComingSoon />}
      </GlobalPageWrapper>
    );
  }
);

export default PoolRewardHistory;
